import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import LinesFromRight from "../components/LinesFromRight";
import styled from "styled-components";
import { fontProxima, mediaMedium } from "../components/styles/variables";
import { Headline64 } from "../components/styles/Headings";
import { Body16 } from "../components/styles/BodyTypes";
import Container from "../components/styles/Container";
import {
  black1,
  brandColor1,
  brandColor1Shade,
  gray4,
  white
} from "../components/styles/Colors";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 39px 0 93px;
  background-color: ${gray4};

  @media (${mediaMedium}) {
    padding: 122px 0 188px;
  }
`;

const Decoration = styled.div`
  position: absolute;
  bottom: 0;
  left: -25%;
  top: 32%;
  display: none;
  width: 100%;

  @media (${mediaMedium}) {
    display: block;
  }
`;

const Title = styled(Headline64)`
  max-width: 500px;
  display: none;
`;

const Content = styled(Body16)`
  margin: -20px 0 0;
  padding: 64px 0;
  position: relative;
  background-color: ${white};
  z-index: 2;

  ol {
    list-style-type: lower-latin;
    font-size: 0.84em;
    padding-left: 1.4em;

    li {
      margin: 0;
    }
  }

  a {
    text-decoration: none;
    color: ${brandColor1};
    transition: color 0.2s;

    svg {
      margin-left: 16px;
    }

    &:hover {
      cursor: pointer;
      color: ${brandColor1Shade};
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${black1};
    margin: 0.45em 0;
    font-weight: 900;
    line-height: 1;
    display: block;
    font-family: ${fontProxima};
    text-transform: uppercase;
  }

  h2 {
    font-size: 56px;
    letter-spacing: -1.5px;
    line-height: 52px;
  }

  h3 {
    font-size: 48px;
    letter-spacing: -1.3px;
  }

  h4 {
    font-size: 44px;
    letter-spacing: -1.2px;
  }

  h5 {
    margin: 32px 0;
    font-size: 32px;
    letter-spacing: -0.8px;
    line-height: 32px;

    @media (${mediaMedium}) {
      font-size: 40px;
      letter-spacing: -1px;
      line-height: 52px;
    }
  }

  h6 {
    font-size: 28px;
    letter-spacing: -0.7px;
    line-height: 36px;

    @media (${mediaMedium}) {
      font-size: 36px;
      letter-spacing: -0.9px;
    }
  }

  table {
    margin: 1.55rem 0.3125rem;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    th {
      background-color: ${gray4};
      border: 1px solid transparent;
      height: 30px;
      padding: 0 1.875rem 0 0.625rem;
    }
    td {
      border: 1px solid transparent;
      height: 30px;
      padding: 0 1.875rem 0 0.625rem;
      background: #ffffff;
      border-bottom: solid 1px #f1f2f3;
    }
  }
`;

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      <SEO
        title="404 Error"
        description="This page has either been deleted or does not exist. We’re sorry about that! Please navigate to our home page for more info."
      />
      <SEO title={frontmatter.title} />

      <Wrapper>
        <Container>
          <Title as="h1">{frontmatter.title}</Title>
        </Container>
        <Decoration>
          <LinesFromRight />
        </Decoration>
      </Wrapper>

      <Content as="div">
        <Container>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Container>
      </Content>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;
